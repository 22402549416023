<template>
<div>
    <nav class="nav navbar is-success" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <router-link class="navbar-item" to="/dashboard">
                    <img src="../../public/logo.png" width="50" height="50" class="nav-image">
                    <h1 class="heading">Square<span>Garden</span></h1>
                </router-link >
                <div class="nav-padding navbar-item nav-mobile">
                    <span class="right">
                        <i class="fas fa-bars " @click="buttonclick" @mouseenter="changeMouseType" @mouseleave="changeMouseTypeBack"></i>
                    </span>
                </div>
            </div>
            <div class="navbar-menu">
                <div class="navbar-end">
                    <div class="nav-padding navbar-item navbar-desctop">
                        <span class="right">
                            <i class="fas fa-bars" @click="buttonclick" @mouseenter="changeMouseType" @mouseleave="changeMouseTypeBack"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <MenuComp/>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import MenuComp from "./MenuComp.vue";

export default {
    name: "NavComp",
    data() {
        return {
        }
    },
    methods: {
        buttonclick() {
            this.toggleMenu();
        },
        ...mapMutations({
            toggleMenu: "SHOW_MENU",
        }),
        changeMouseType() {
            document.body.style.cursor = "pointer";
         },
         changeMouseTypeBack() {
            document.body.style.cursor = "default";
         },
    },
    components: {
        MenuComp,
    },
    computed: {
        ...mapState({
            showMenu: state => state.showMenu
        })
    }
}
</script>

<style scoped>
.nav {
    background: #94C973;
    width: 100%;
    border-bottom: 10px solid #2F5233;
}

.nav-mobile {
    visibility: hidden;
}

h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 32px;
}

h1.heading span {
    font-weight: bold;
}

i.fas {
    margin-right: 32px;
}

/* Media Screens */

@media screen and (max-width: 1023px){
    .nav-mobile{
        visibility: visible;
    }
    .right {
        margin-left: 1em;
    }
}

/* For IPhone 5*/
@media screen and (max-width: 350px){
    h1.heading {
        font-size: 1em;
        margin-left: 10px;
    }
}

/* Galaxy Fold */
@media screen and (max-width: 300px){
    h1.heading {
        font-size: 1em;
        margin-left: 5px;
    }
    .right {
        margin: 0;
    }
}
    
/*
@media screen and (max-width: 980px){
    .right {
        margin-left: 50%;
    }
}

@media screen and (max-width: 880px){
    .right {
        margin-left: 550px;
    }
}

@media screen and (max-width: 830px){
    .right {
        margin-left: 500px;
    }
}

@media screen and (max-width: 780px){
    .right {
        margin-left: 450px;
    }
}

@media screen and (max-width: 730px){
    .right {
        margin-left: 400px;
    }
}

@media screen and (max-width: 680px){
    .right {
        margin-left: 350px;
    }
}

@media screen and (max-width: 630px){
    .right {
        margin-left: 300px;
    }
}

@media screen and (max-width: 580px){
    .right {
        margin-left: 250px;
    }
}

@media screen and (max-width: 530px){
    .right {
        margin-left: 200px;
    }
}

@media screen and (max-width: 480px){
    .right {
        margin-left: 150px;
    }
}

@media screen and (max-width: 430px){
    .right {
        margin-left: 100px;
    }
}

@media screen and (max-width: 380px){
    .right {
        margin-left: 50px;
    }
}*/
</style>