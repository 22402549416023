<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar
        position="fixed"
        type="is-light"
        :fullheight="fullheight"
        :right="right"
        :open="show"
        :overlay="overlay"
        :can-cancel="can_cancel"
        :on-cancel="toggleMenu"
      >
        <div class="p-1">
          <div class="block">
            <h2 class="heading">Square<span>Garden</span></h2>
          </div>
          <b-menu>
            <b-menu-list :label="`${$t('menu.headings.menu')}`">
              <router-link to="/dashboard">
                <p @click="clickMenu">
                  <i class="fas fa-home"></i> {{ $t("menu.menu.home") }}
                </p>
              </router-link>
              <!--<router-link to="/profile"><p @click="clickMenu" class="p-1"><i class="far fa-id-card"></i> User Profile</p></router-link>-->
              <router-link to="/content">
                <p @click="clickMenu" class="p-1">
                  <i class="fas fa-database"></i> {{ $t("menu.menu.content") }}
                </p>
              </router-link>
              <router-link to="/tutorial">
                <p @click="clickMenu" class="p-1">
                  <i class="fas fa-question"></i> {{ $t("menu.menu.tutorial") }}
                </p>
              </router-link>
              <div class="p-auto">
                <p class="p-1">
                  <i class="far fa-flag icon"></i
                  >{{ $t("menu.menu.language") }} <languageSwitcher class="" />
                </p>
              </div>
              <router-link to="/weather">
                <p @click="clickMenu" class="p-1">
                  <i class="fas fa-cloud"></i> {{ $t("menu.menu.weather") }}
                </p>
              </router-link>
            </b-menu-list>
            <!-- TODO: including a Weather API and geolocation
                        <b-menu-list label="Weather API">
                            <b-menu-item label="Weather API einschalten?" icon="home"></b-menu-item>
                            <b-menu-item label="Hierfür muss ebenfalls die Geolocation eingeschaltet werden" icon="account"></b-menu-item>
                        </b-menu-list> -->
            <b-menu-list :label="`${$t('menu.headings.endings')}`">
              <router-link to="/seasons"
                ><p class="p-1" @click="clickMenu">
                  <i class="fas fa-book-open"></i>
                  {{ $t("menu.endings.title") }}
                </p></router-link
              >
            </b-menu-list>
            <b-menu-list :label="`${$t('menu.headings.logout')}`">
              <router-link to="/"
                ><p class="p-1" @click="logout_click">
                  <i class="fas fa-sign-out-alt"></i
                  >{{ $t("menu.logout.title") }}
                </p></router-link
              >
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import languageSwitcher from "@/components/multilang/languageSwitcher";
import { logout } from "../db/auth.js";

export default {
  data() {
    return {
      right: true,
      fullheight: true,
      overlay: false,
      can_cancel: true,
    };
  },
  components: {
    languageSwitcher,
  },
  methods: {
    ...mapMutations({
      toggleMenu: "SHOW_MENU",
    }),
    clickMenu: function (e) {
      if (e.target.nodeName === "I") {
        e.target.parentNode.classList.add("clicked");
        setTimeout(() => {
          e.target.parentNode.classList.remove("clicked");
        }, 200);
        this.toggleMenu();
      }
      if (e.target.nodeName === "P") {
        e.target.classList.add("clicked");
        setTimeout(() => {
          e.target.classList.remove("clicked");
        }, 200);
        this.toggleMenu();
      }
    },
    logout_click: function (e) {
      e.target.classList.add("clicked");
      setTimeout(() => {
        e.target.classList.remove("clicked");
      }, 200);
      logout();
      this.toggleMenu();
    },
  },
  computed: {
    ...mapState({
      show: (state) => state.showMenu,
    }),
  },
};
</script>

<style scoped>
h2.heading {
  text-transform: uppercase;
  color: #107869;
  font-size: 16px;
}

h2.heading span {
  font-weight: bold;
}

.p-1 {
  padding: 1em;
  padding-left: 4em;
}

.p-auto {
  padding-left: 0.7em;
}

.icon {
  padding-right: 3px;
}

.clicked {
  background: #b1d8b7;
  border-radius: 5px;
}
</style>
